import { Component, OnInit, ViewChildren, QueryList, ViewChild, ElementRef, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { UserAuthHelper } from '@helpers/UserAuthHelper';
import { UntypedFormGroup } from '@angular/forms';
import { BookWithoutRegistrationComponent } from './_shared/book-without-registration/book-without-registration.component';
import { Router } from '@angular/router';
import ConfigService from '@config/ConfigService';
import { RouteMapHelper } from '@helpers/RouteMapHelper';
import {UserService} from '@services/auth/user.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import { takeUntil, first } from 'rxjs/operators';
import { I18nRoutePipe } from 'src/app/_pipes/i18n-route.pipe';
import { ReservationStepService } from '@services/reservations/reservation-step.service';
import IReservationStep from '../../_interfaces/IReservationStep';
import ReservationStepEnum from '../../_enums/ReservationStepEnum';
import { ValidationEmitterService } from 'src/app/_subprojects/reservation-form/_services/validation-emitter.service';
import { LineArrowComponent } from '../../_shared/line-arrow/line-arrow.component';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ReservationDataService } from '@services/reservations/reservation-data.service';
import { LayoutService } from '@services/layout.service';

@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss']
})
export class StepFourComponent extends SubscribeHelper implements OnInit, OnDestroy {
  activeRoomIndex = 0;
  loggedIn = false;
  errorMsg = '';
  buttonBlocked = false;
  loading = false;
  // msgVisible = false;
  refreshError = false;
  nextStepMsg: string = null;

  @ViewChild('bookData') bookData: BookWithoutRegistrationComponent;
  @ViewChild('lineArrow') lineArrow: LineArrowComponent;

  constructor(
    private userService: UserService,
    private router: Router,
    private i18nRoute: I18nRoutePipe,
    private stepService: ReservationStepService,
    private validationEmitterService: ValidationEmitterService,
    private reservationDataService: ReservationDataService,
    private layoutService: LayoutService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document
  ) {
    super();
  }

  ngOnInit(): void {
    this.loggedIn = this.userService.isLoggedIn$.value;

    if ( this.userService.userValue ) {
      /**
       * Odśwież dane na kroku 4
       */
      this.userService.checkIsLoggedIn( true )
        .pipe(
          takeUntil(this.componentDestroyed$)
        )
        .subscribe( isLoggedIn => {
          this.loggedIn = isLoggedIn;

          if (this.loggedIn === true) {
            this.activeRoomIndex = 2;
          }
        });
    }

    this.userService.isLoggedIn().subscribe( isLoggedIn => {
      this.loggedIn = isLoggedIn;

      if ( this.loggedIn === true ) {
        this.activeRoomIndex = 2;
      }
      else {
        this.activeRoomIndex = null;
      }
    });

    this.subscribeToStepsChange();
    this.subscribeToStepFive();
  }

  subscribeToStepsChange() {
    this.stepService
      .getSteps()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((steps: IReservationStep[]) => {

        this.buttonBlocked = !steps.find(step => step.id === ReservationStepEnum.IV).isValid;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  clickOnTab(index: number): void {
    this.activeRoomIndex = this.activeRoomIndex === index ? null : index;
  }

  onLogin(_: UntypedFormGroup): void {
    window.scrollTo(0, 0);

    this.loggedIn = true;
    this.activeRoomIndex = 2;

    this.layoutService.setItemLoader(true);
    this.userService.getMe().subscribe();
  }

  subscribeToStepFive() {
    this.validationEmitterService
      .getFourthStepValidationToggle()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(val => {
        this.nextStepMsg = val;
        this.nextStep();
      });
  }

  nextStep(): void {
    this.activeRoomIndex = 2;
    this.refreshError = false;

    if ( !this.loading ) {
      if ( this.bookData.validateAndSave() ) {
        this.loading = true;
        this.nextStepMsg = null;

        this.reservationDataService
          .validateFourth()
          .pipe(
            takeUntil(this.componentDestroyed$)
          )
          .subscribe(_ => {
            this.loading = false;
            this.nextStepMsg = null;

            this.stepService.setStepValidity(ReservationStepEnum.IV, true);
            this.router.navigate(
              [ this.i18nRoute.transform('/:lang/reservation/step-5') ]
            );
          }, err => {
            this.bookData.setErrorMessages({});
            this.nextStepMsg = null;

            if (
              err.company?.form_errors?.id
              || err.company?.messages
              || err.customer?.messages
              || err.messages
              || typeof err === 'string'
            ) {
              this.refreshError = true;
            }

            if ( isPlatformBrowser(this.platformId) ) {
              setTimeout(() => {
                this.bookData.setErrorMessages(err);
              });
            }

            this.loading = false;
            this.stepService.setStepValidity(ReservationStepEnum.IV, false);
            this.scrollToBottom();
          });
      } else {
        this.nextStepMsg = 'reservation_error_text_4';
        this.scrollToBottom();
      }
    }
  }

  private scrollToBottom() {
    if ( typeof window.scrollTo === 'function' ) {
      const supportsNativeSmoothScroll = 'scrollBehavior' in this.document.documentElement.style;

      if ( supportsNativeSmoothScroll ) {
        window.scrollTo({
          top: this.document.documentElement.scrollHeight,
          behavior: 'smooth'
        });
      }
      else {
        window.scrollTo(0, this.document.documentElement.scrollHeight);
      }
    }
  }
}
