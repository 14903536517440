import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {ReservationService} from '../_services/reservation.service';
import IReservationDate from '../_interfaces/IReservationDate';
import * as moment from 'moment';

@Injectable()
export class LangChangeGuard  {
  constructor( public reservationService: ReservationService ) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      const langChange = !!this.reservationService.reservationData.data.replaceDataAfterLangChanged$.value;

      if ( langChange ) {
        if ( this.reservationService.reservationData.getRoomsValue().length ) {
          const rooms = this.reservationService.reservationData.getRoomsValue();
          const date: IReservationDate = this.reservationService.reservationData.data.date$.value;

          if ( date.from && date.to && rooms.length ) {
            const dateFrom = moment([date.from.year, date.from.month - 1, date.from.day]).format('YYYY-MM-DD');
            const dateTo = moment([date.to.year, date.to.month - 1, date.to.day]).format('YYYY-MM-DD');

            this.reservationService.getRoomsStandards(dateFrom, dateTo, rooms).subscribe(data => {
              this.reservationService.replaceRoomsNames(rooms, data.rooms);

              if (this.reservationService.hasExtras(rooms)) {
                this.reservationService.getExtras(dateFrom, dateTo, rooms).subscribe(data => {
                  this.reservationService.reservationData.data.replaceDataAfterLangChanged$.next(0);
                  obs.next(true);
                }, err => {
                  this.reservationService.reservationData.data.replaceDataAfterLangChanged$.next(0);
                  obs.next(true);
                });
              } else {
                this.reservationService.reservationData.data.replaceDataAfterLangChanged$.next(0);
                obs.next(true);
              }
            }, err => {
              this.reservationService.reservationData.data.replaceDataAfterLangChanged$.next(0);
              obs.next(true);
            });
          }
        }
        else {
          this.reservationService.reservationData.data.replaceDataAfterLangChanged$.next( 0 );
          obs.next(true);
        }
      }
      else {
        obs.next(true);
      }

      obs.next(true);

      // this.reservationService.getRoomsStandards();
    });
  }
}
