import { Subject } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';

@Component({
  template: '',
})
// tslint:disable-next-line:component-class-suffix
export class SubscribeHelper implements OnDestroy {
  protected componentDestroyed$: Subject<boolean> = new Subject();

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
