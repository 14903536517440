import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '@services/auth/user.service';
import {BlogostanService} from '../_services/blogostan.service';
import {I18nRoutePipe} from '../../../_pipes/i18n-route.pipe';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HasAccessGuard  {

  constructor(
    private router: Router,
    private userService: UserService,
    private i18nRoutePipe: I18nRoutePipe,
    private blogostanService: BlogostanService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const user = this.userService.userValue;
    if (user) {
      return this.blogostanService.hasAccess().pipe(
        map(extra => {
          if (extra.hasAccess) {
            if ( typeof window !== 'undefined' ) {
              if (window.parent) {
                window.parent.location.href = `${environment.blogostganAuthUrl}${user.token}`;
              }
            }

            return false;
          } else {
            return true;
          }
        })
      );
    }
    return false;
  }

}
