import {Component, OnInit, Inject, OnChanges} from '@angular/core';
import {fadeAnimation} from '@helpers/animations';
import {ReservationDataService} from '@services/reservations/reservation-data.service';
import {NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CustomDatepickerI18n} from './_shared/datepicker/datepicker-i18n';
import * as moment from 'moment';
import {ScrollToService} from '@hkjeffchan/ngx-scroll-to';
import {ReservationService} from '../../_services/reservation.service';
import {LayoutService} from '@services/layout.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {delay, takeUntil} from 'rxjs/operators';
import { ReservationStepService } from '@services/reservations/reservation-step.service';
import { I18nRoutePipe } from 'src/app/_pipes/i18n-route.pipe';
import ReservationStepEnum from '../../_enums/ReservationStepEnum';
import IReservationStep from '../../_interfaces/IReservationStep';
import IReservationRoom from '../../_interfaces/IReservationRoom';
import { ValidationEmitterService } from 'src/app/_subprojects/reservation-form/_services/validation-emitter.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IReservationOffer } from '../../_interfaces/IReservationOffer';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss'],
  animations: [
    fadeAnimation
  ]
})
export class StepOneComponent extends SubscribeHelper implements OnInit {
  showCalendar = false;
  fromDate: NgbDateStruct = null;
  toDate: NgbDateStruct = null;
  maxDate: NgbDate = null;
  prices: {} = {};
  offer: IReservationOffer;
  buttonBlocked = false;
  rooms: IReservationRoom[];

  nightsOfStay: number = null;
  nextStepMsg: string = null;
  offerSlug: string = null;

  constructor(
    private reservationData: ReservationDataService,
    private reservation: ReservationService,
    public i18n: CustomDatepickerI18n,
    private scrollToService: ScrollToService,
    private stepService: ReservationStepService,
    private validationEmitterService: ValidationEmitterService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document
  ) {
    super();
  }

  ngOnInit(): void {
    this.reservationData
      .getDate()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( date => {
        this.fromDate = date.from;
        this.toDate = date.to;

        this.setDaysOfStay();
      });

    this.getOfferSlug();
    this.subscribeToStepsChange();
    this.reservationData.validateFirst();

    this.subscribeToStepTwo();
  }

  subscribeToStepsChange() {
    this.stepService
      .getSteps()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((steps: IReservationStep[]) => {
        this.buttonBlocked = !steps.find(step => step.id === ReservationStepEnum.I).isValid;

        if ( !this.buttonBlocked ) {
          this.nextStepMsg = null;
        }
      });
  }

  toggleCalendar( e: MouseEvent ) {
    e.preventDefault();

    this.showCalendar = !this.showCalendar;

    if ( this.showCalendar ) {
      this.scrollToService.scrollTo({
        target: '#date-of-stay',
        offset: -40
      });
    }
  }

  hideCalendar( val: boolean ) {
    this.showCalendar = false;
  }

  dateSelected( value: boolean ): void {
    this.showCalendar = false;
    const rooms = this.reservationData.getRooms().value;
    rooms.forEach(room => {
      room.price = null;
      room.priceType = null;
      room.standardId = null;
      room.name = null;
    });

    this.reservationData.setRooms(rooms);
  }

  setDaysOfStay(): void {
    if ( this.fromDate && this.toDate ) {
      this.nightsOfStay = this.reservationData.getNightsOfStay();
    }
  }

  /**
   * pobieramy dane do cennika
   * załozenie jest takie, ze na rok powinien byc uzupelniony cennik
   */
  getCalendarPrices(): void {
    const today = moment().format('YYYY-MM-DD');
    const lastDayOfNextYear = moment().add('1', 'y').dayOfYear(31).month(11).format('YYYY-MM-DD');

    this.reservation
      .getCalendarPrices( today, lastDayOfNextYear, this.offerSlug )
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( data => {
        this.setMaxDate(data.meta.maxDate);
        this.prices = data.results;
        this.offer = data.offer;

        if (this.offer && this.fromDate && this.toDate) {
          this.clearSelectedDate();
        }

        this.reservationData.setReservationOffer(data?.offer);
        this.reservationData.setShuummePointsPercent(data?.meta?.bookingPercent);
      });
  }

  isOfferRange(date: NgbDate) {
    const minDate = new Date(this.offer.minDate);
    const exludedDateFrom = new NgbDate(minDate.getUTCFullYear(), minDate.getUTCMonth() + 1, minDate.getUTCDate());

    const maxDate = new Date(this.offer.maxDate);
    const exludedDateTo = new NgbDate(maxDate.getUTCFullYear(), maxDate.getUTCMonth() + 1, maxDate.getUTCDate());

    return date.equals(exludedDateTo) || date.equals(exludedDateFrom) || date.after(exludedDateFrom) && date.before(exludedDateTo);
  }

  clearSelectedDate() {
    const ngbFromDate = new NgbDate(this.fromDate.year, this.fromDate.month, this.fromDate.day);
    const ngToDate = new NgbDate(this.toDate.year, this.toDate.month, this.toDate.day);

    if ((!this.isOfferRange(ngbFromDate) || !this.isOfferRange(ngToDate))) {
      this.reservationData.setDate({
        from: null,
        to: null
      }, false);
    }
  }

  setMaxDate( date: string ): void {
    try {
      const maxDate = moment(date);

      this.maxDate = new NgbDate(
        parseInt(maxDate.format('YYYY'), 10),
        parseInt(maxDate.format('M'), 10),
        parseInt(maxDate.format('D'), 10)
      );
    }
    catch (e) {
      this.maxDate = null;
    }
  }

  /**
   * Po kliknięciu w przycisk dalej
   * lub przejściu do kroku 2 poprzez nawigację boczną,
   * pokaż błędy na kroku
   */
  subscribeToStepTwo() {
    this.validationEmitterService
      .getFirstStepValidationToggle()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(res => {
        this.nextStepMsg = res;

        if ( typeof window.scrollTo === 'function' ) {
          const supportsNativeSmoothScroll = 'scrollBehavior' in this.document.documentElement.style;

          if ( supportsNativeSmoothScroll ) {
            window.scrollTo({
              top: this.document.documentElement.scrollHeight,
              behavior: 'smooth'
            });
          }
          else {
            window.scrollTo(0, this.document.documentElement.scrollHeight);
          }
        }
      });
  }

  getOfferSlug() {
    this.route.queryParams.pipe(delay(0)).subscribe(queryParams => {
      const queryParamsKeys = Object.keys(queryParams);
      const offerKey = queryParamsKeys.filter(key => this.translateService.instant(key).toLowerCase() === this.translateService.instant('offer').toLowerCase()).pop();  
  
      if(offerKey) {
        this.offerSlug = queryParams[offerKey];
      }

      this.getCalendarPrices();
    });
  }
}
