import { Component, OnInit } from '@angular/core';
import ConfigService from '@config/ConfigService';
import {ConfigurationService} from '@services/configuration.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {IFacility} from '@interfaces/IFacility';
import { IBookingType } from 'src/app/_subprojects/reservation/_interfaces/IBookingType';
import {ContactFormService} from '../../../../../../_services/contact-form.service';
import {ScrollToService} from '@hkjeffchan/ngx-scroll-to';
import { IConfiguration } from '@interfaces/IConfiguration';
import ApiConfigurationEndpointEnum from '@enums/ApiConfigurationEndpointEnum';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.scss']
})
export class FacilitiesComponent extends SubscribeHelper implements OnInit {

  bookingType: IBookingType[] = [
    {
      id: 4,
      label: 'ask_pillow_menu',
      email: 'wellness@shuumhotel.pl'
    },
    {
      id: 5,
      label: 'ask_bike_rental',
      email: 'recepcja@shuumhotel.pl'
    },
    {
      id: 6,
      label: 'ask_electric_car_charging',
      email: 'recepcja@shuumhotel.pl'
    }
  ];

  public hasSrcSetSupport: boolean;
  pillowMenuFile: IConfiguration = null;
  facilities: IFacility[] = [];

  constructor(
    private apiConfiguration: ConfigurationService,
    private contactFormService: ContactFormService,
    private scrollToService: ScrollToService
  ) {
    super();
    this.hasSrcSetSupport = ConfigService.getHasSrcSetSupport();
  }

  ngOnInit(): void {
    this.getPillowMenuFile();
    this.getFacilities();
  }

  getPillowMenuFile(): void {
    this.apiConfiguration
      .getConfiguration(ApiConfigurationEndpointEnum.PillowMenu)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError(err => {
          this.pillowMenuFile = { id: null, value: '', iso: '', name: '' };
          return err;
        })
      )
      .subscribe((res: IConfiguration) => {
        this.pillowMenuFile = res;
      });
  }
  
  getFacilities(): IFacility[] {
    const path = '../../../assets/images/reservations/loyalty-program/facilities/';

    return this.facilities = [
      {
        id: 5,
        header: 'bike_rental',
        description: 'bike_rental_desc',
        image: {
          image: path + 'bike-rental.jpg',
          alt: 'bike_rental'
        }
      },
      {
        id: 6,
        header: 'electric_car_charging',
        description: 'electric_car_charging_desc',
        image: {
          image: path + 'electric-car-charging.jpg',
          alt: 'electric_car_charging'
        }
      }
    ];
  }

  scrollTo( e: MouseEvent ): void {
    e.preventDefault();

    const scrollToConfig = {
      target: 'contactForm',
      duration: 300
    };

    this.scrollToService
      .scrollTo(scrollToConfig);
  }

  setElementInfo = ( e: MouseEvent, name: string, id: number ) => {
    if ( window ) {
      this.contactFormService.setBookingType({
        id,
        extra: {
          name: name.toLowerCase()
        }
      });
    }

    this.scrollTo(e);
  }

}
