<div>
  <app-steps *ngIf="layoutExtrasVisible"></app-steps>
  <span class="line" *ngIf="!layoutExtrasVisible"></span>
  <div class="pt-zetta" [ngClass]="{ 'isSideSummaryVisible': isSideSummaryVisible }">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-xl-10">
          <div class="row">
            <div class="col-12">
              <div [@routeAnimation]="o.activatedRouteData">
                <router-outlet #o="outlet"></router-outlet>
              </div>
              <app-side-summary></app-side-summary>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-info-modal-container></app-info-modal-container>
