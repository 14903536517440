import { Component, OnInit } from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {ScrollToService} from '@hkjeffchan/ngx-scroll-to';
import {ContactFormService} from '../../../../../../_services/contact-form.service';
import {UserService} from '@services/auth/user.service';
import {IUserTreatments} from '@interfaces/IUserTreatments';
import { IBookingType } from 'src/app/_subprojects/reservation/_interfaces/IBookingType';
import * as isArray from 'lodash/isArray';
import { ITreatment } from '@interfaces/ITreatment';

@Component({
  selector: 'app-discover-relax-and-beauty',
  templateUrl: './discover-relax-and-beauty.component.html',
  styleUrls: ['./discover-relax-and-beauty.component.scss']
})
export class DiscoverRelaxAndBeautyComponent extends SubscribeHelper implements OnInit {

  treatments: IUserTreatments;

  bookingType: IBookingType[] = [
    {
      id: 1,
      label: 'book_treatment',
      extra: null,
      email: 'wellness@shuumhotel.pl',
      default: true
    },
  ];

  treatmentNames: { [key: string]: string }[] = [];

  constructor(
    private scrollToService: ScrollToService,
    private contactFormService: ContactFormService,
    private userService: UserService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getTreatmentList();
  }

  getTreatmentList() {
    this.userService.getTreatments()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((list: IUserTreatments) => {
        this.treatments = list;

        for(let t in this.treatments) {
          if (isArray(this.treatments[t])) {
            this.treatments[t].map((treatment: ITreatment) => this.treatmentNames.push({
              type: 'book_treatment',
              name: treatment.name
            }));
          } else {
            this.treatmentNames.push({
              type: 'book_treatment',
              name: this.treatments[t].name
            });
          }
        }
      });
  }

  scrollTo( e: MouseEvent ): void {
    e.preventDefault();

    const scrollToConfig = {
      target: 'contactForm',
      duration: 300
    };

    this.scrollToService
      .scrollTo(scrollToConfig);
  }

  setElementInfo = ( e: MouseEvent, name: string ) => {
    if ( window ) {
      this.contactFormService.setBookingType({
        id: 1,
        extra: {
          name
        }
      });
    }

    this.scrollTo(e);
  }

}
