import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { IGuestData } from '../../_subprojects/reservation-form/_interfaces/IGuestData';
import { requiredConditionalValidator, checkboxValidate, requieIfEnabledValidator, lengthConditionalValidator, lengthIfEnabledValidator, patternConditionalValidator, patternIfEnabledValidator } from '../../_subprojects/reservation/_helpers/validators';
import GlobalFunctions from '@helpers/GlobalFunctions';
import IReservationRoom from '../../_subprojects/reservation-form/_interfaces/IReservationRoom';
import { User, IUser } from 'src/app/_models/auth/User';
import { RuleService } from '@services/auth/rule.service';
import IRule from '@interfaces/IRule';
import RuleEnum from '@enums/RuleEnum';
import ReservationConfig from '../../_subprojects/reservation-form/_config/ReservationConfig';
import { Subject } from 'rxjs';

/**
 * Przechowuje dane dot. formularzy w 4 kroku rezerwacji
 * Dane są walidowane
 * Formularz jest inicjalizowany w kroku 4
 * oraz przy walidacji kroku 4
 */
@Injectable({
  providedIn: 'root'
})
export class FormsDataValidationService {
  roomNameForms: UntypedFormGroup[];
  dataForm: UntypedFormGroup;
  agreementsForm: UntypedFormGroup;
  rules: IRule[] = [];
  private _return: Subject<string> = new Subject<string>();

  selectAges: number[] = ReservationConfig.getReservationChildrenAges();
  childrenForms: UntypedFormGroup[] = [];

  rulesReady$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ruleService: RuleService
  ) { }

  setupForms(rooms: IReservationRoom[], user?: User) {
    const savedData = rooms[ 0 ]?.guestData || null;
    const emailRegex = GlobalFunctions.getEmailRegex();
    const phoneRegex = GlobalFunctions.getPhoneRegex();

    this.dataForm = this.formBuilder.group({
      name: ['', [requieIfEnabledValidator, lengthIfEnabledValidator(3, 100)]],
      lastName: ['', [requieIfEnabledValidator, lengthIfEnabledValidator(3, 100)]],
      email: [
        '',
        [ requieIfEnabledValidator,
          patternIfEnabledValidator(emailRegex),
          lengthIfEnabledValidator(3, 100)
        ]],
      phone: [
        '',
        [ requieIfEnabledValidator,
          patternIfEnabledValidator(phoneRegex),
          lengthIfEnabledValidator(null, 20)
        ]],
      street: ['', [requieIfEnabledValidator, lengthIfEnabledValidator(null, 50)]],
      postcode: ['', [requieIfEnabledValidator, lengthIfEnabledValidator(null, 10)]],
      city: ['', [requieIfEnabledValidator, lengthIfEnabledValidator(null, 50)]],
      country: ['', [requieIfEnabledValidator]],
      vatToggle: [false],
      vatCompanyName: [
        '',
        [ requiredConditionalValidator('vatToggle', savedData?.vatToggle),
          lengthConditionalValidator('vatToggle', savedData?.vatToggle, 3, 100)
        ]],
      vatStreet: [
        '',
        [ requiredConditionalValidator('vatToggle', savedData?.vatToggle),
          lengthConditionalValidator('vatToggle', savedData?.vatToggle, null, 50)
        ]],
      vatPostcode: [
        '',
        [ requiredConditionalValidator('vatToggle', savedData?.vatToggle),
          lengthConditionalValidator('vatToggle', savedData?.vatToggle, null, 10)
        ]],
      vatCity: [
        '',
        [ requiredConditionalValidator('vatToggle', savedData?.vatToggle),
          lengthConditionalValidator('vatToggle', savedData?.vatToggle, null, 50)
        ]],
      vatNip: [
        '',
        [ requiredConditionalValidator('vatToggle', savedData?.vatToggle),
          lengthConditionalValidator('vatToggle', savedData?.vatToggle, null, 20)
        ]],
      vatCountry: ['', [requiredConditionalValidator('vatToggle', savedData?.vatToggle)]],
      vatOtherToggle: [false],
      otherCustomerToggle: [false],
      otherCustomerName: [
        '',
        [ requiredConditionalValidator('otherCustomerToggle', savedData?.otherCustomerToggle),
          lengthConditionalValidator('otherCustomerToggle', savedData?.otherCustomerToggle, 3, 100)
        ]],
      otherCustomerLastname: [
        '',
        [ requiredConditionalValidator('otherCustomerToggle', savedData?.otherCustomerToggle),
          lengthConditionalValidator('otherCustomerToggle', savedData?.otherCustomerToggle, 3, 100)
        ]],
      otherCustomerEmail: [
        '',
        [ requiredConditionalValidator('otherCustomerToggle', savedData?.otherCustomerToggle),
          patternConditionalValidator('otherCustomerToggle', savedData?.otherCustomerToggle, emailRegex),
          lengthConditionalValidator('otherCustomerToggle', savedData?.otherCustomerToggle, 3, 100)
        ]],
      otherCustomerPhone: [
        '',
        [ requiredConditionalValidator('otherCustomerToggle', savedData?.otherCustomerToggle),
          lengthConditionalValidator('otherCustomerToggle', savedData?.otherCustomerToggle, null, 20),
          patternConditionalValidator('otherCustomerToggle', savedData?.otherCustomerToggle, phoneRegex),
        ]],
      otherCustomerConfirm: [true],
    });

    this.setFormInitialValues(savedData, user);

    this.agreementsForm = this.formBuilder.group({
      rules: this.formBuilder.array([])
    });

    this.getRules( savedData );

    this.roomNameForms = [];

    rooms.forEach((room, index) => {
      if ( index > 0 ) {
        this.roomNameForms.push(this.formBuilder.group({
          name: [room.guestData?.name || '', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
          lastName: [room.guestData?.lastName || '', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]]
        }));
      }
    });
  }

  setupChildrenForms( rooms: IReservationRoom[] ) {
    this.childrenForms = [];

    rooms.forEach((room, i) => {
      this.childrenForms.push(
        this.formBuilder.group({
          ages: this.formBuilder.array([])
        })
      );

      const formAges = this.childrenForms[i].get('ages') as UntypedFormArray;

      if ( room.children > 0 ) {

        if ( room.children >= formAges.controls.length ) {
          for ( let i = formAges.controls.length; i < room.children; i++ ) {
            formAges.push(this.formBuilder.control(room.childrenAges[i] >= 0 ? room.childrenAges[i] : null, [
              Validators.required,
              Validators.min(0),
              Validators.max(this.selectAges.length),
              Validators.pattern(/[0-9]+/)
            ]));
          }
        }
        else {
          formAges.controls.splice(room.children);
        }
      }
      else {
        formAges.clear();
      }
    });
  }

  getFormAges(i) {
    return this.childrenForms[i].get('ages') as UntypedFormArray;
  }

  get f() { return this.agreementsForm.controls; }
  get r() { return this.f.rules as UntypedFormArray; }

  private getRules( savedData: IGuestData ) {
    this.ruleService.getRules(RuleEnum.Reservation).subscribe(data => {
      this.r.clear();
      this.rules = data?.rules;
      this._return.next(data?.meta?.return);
      this.rules.forEach((rule, index) => {
        let savedRule = null;

        if ( typeof savedData?.rules[index] !== 'undefined' ) {
          if ( typeof savedData?.rules[index]['rule_' + rule.id] !== 'undefined' ) {
            savedRule = savedData?.rules[index]['rule_' + rule.id];
          }
        }

        let config = [savedRule || null, []];

        if (!rule.isVoluntary) {
          config = [savedRule || null, [checkboxValidate]];
        }

        this.r.push(this.formBuilder.group({
          ['rule_' + rule.id]: config
        }));
      });

      this.rulesReady$.next();
    });
  }

  patchDataForm(data: any) {
    this.dataForm.patchValue(data);
  }

  getDataForm(): UntypedFormGroup {
    return this.dataForm;
  }

  getAgreementsForm(): UntypedFormGroup {
    return this.agreementsForm;
  }

  getRoomNameForms(): UntypedFormGroup[] {
    return this.roomNameForms;
  }

  getChildrenForms(): UntypedFormGroup[] {
    return this.childrenForms;
  }

  getReturnData() {
    return this._return.asObservable();
  }

  areFormsValid(): boolean {
    let formsValid = true;

    this.roomNameForms.forEach(form => {
      if ( !form.valid ) {
        formsValid = false;
      }
    });

    if ( !formsValid ) {
      return false;
    }
    if ( !this.dataForm.valid ) {
      return false;
    }

    if ( !this.agreementsForm.valid ) {
      return false;
    }

    return true;
  }

  areFormsEmpty(): boolean {
    if ( !this.roomNameForms || !this.dataForm || !this.agreementsForm ) {
      return true;
    }

    return false;
  }

  areChildrenFormsEmpty(): boolean {
    if ( this.childrenForms.length === 0 ) {
      return true;
    }

    return false;
  }



  setFormInitialValues( savedData: IGuestData, user: IUser ) {
    const vatValues = {
      vatToggle:
        savedData?.vatToggle ?? (user?.company?.id ? true : false),
      vatCompanyName:
        savedData?.vatOtherToggle ? savedData?.vatCompanyName || '' : user?.company?.name || '',
      vatStreet:
        savedData?.vatOtherToggle ? savedData?.vatStreet || '' : user?.company?.address || '',
      vatPostcode:
        savedData?.vatOtherToggle ? savedData?.vatPostcode || '' : user?.company?.postcode || '',
      vatCity:
        savedData?.vatOtherToggle ? savedData?.vatCity || '' : user?.company?.city || '',
      vatNip:
        savedData?.vatOtherToggle ? savedData?.vatNip || '' : user?.company?.taxNumber || '',
      vatCountry:
        savedData?.vatOtherToggle ? savedData?.vatCountry || '' : user?.company?.country || '',
      vatOtherToggle:
        !user?.company?.id ? true : savedData?.vatOtherToggle ?? false
    };

    const otherCustomerValues = {
      otherCustomerToggle: savedData?.otherCustomerToggle ?? false,
      otherCustomerName: savedData?.otherCustomerName || '',
      otherCustomerLastname: savedData?.otherCustomerLastname || '',
      otherCustomerEmail: savedData?.otherCustomerEmail || '',
      otherCustomerPhone: savedData?.otherCustomerPhone || '',
      otherCustomerConfirm: savedData?.otherCustomerConfirm || false
    };

    const userValues = user && user.complete ? {
      name: user?.name || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phone: user?.phone || '',
      street: user?.address || '',
      postcode: user?.postcode || '',
      city: user?.city || '',
      country: user?.country || ''
    } : {
      name: user?.name || savedData?.name || '',
      lastName: user?.lastName || savedData?.lastName || '',
      email: user?.email || savedData?.email || '',
      phone: user?.phone || savedData?.phone || '',
      street: user?.address || savedData?.street || '',
      postcode: user?.postcode || savedData?.postcode || '',
      city: user?.city || savedData?.city || '',
      country: user?.country || savedData?.country || ''
    };

    const finalValue = { ...userValues, ...vatValues, ...otherCustomerValues };
    this.dataForm.patchValue(finalValue);
  }
}
