import { Component, OnInit, ViewChild, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { PopupContentComponent } from 'src/app/_subprojects/reservation/_shared/popup-content/popup-content.component';
import { InputField } from 'src/app/_subprojects/reservation/_helpers/InputField';
import { matchingFieldsValidate } from 'src/app/_subprojects/reservation/_helpers/validators';
import {UserService} from '@services/auth/user.service';
import {User} from '../../../../../../_models/auth/User';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {takeUntil, finalize} from 'rxjs/operators';
import GlobalFunctions from '@helpers/GlobalFunctions';
import { Router } from '@angular/router';
import { I18nRoutePipe } from 'src/app/_pipes/i18n-route.pipe';
import { GlobalStateHelper } from '@helpers/GlobalStateHelper';
import { IBookingType } from 'src/app/_subprojects/reservation/_interfaces/IBookingType';
import {RoleEnum} from '@enums/RoleEnum';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})

/**
 * Zmiana hasła, kiedy użytkownik jest zalogowany
 */
export class UserAccountComponent extends SubscribeHelper implements OnInit, OnDestroy {
  @ViewChild('popupNewPassword') popupNewPassword: PopupContentComponent;
  @ViewChildren('input') input: QueryList<InputField>;

  newPasswordForm: UntypedFormGroup;
  user: User;
  successMsg = '';
  errorMsg = '';
  loading = false;

  errors: any = null;

  bookingType: IBookingType[] = [
    {
      id: 1,
      label: 'ask_question',
      email: 'recepcja@shuumhotel.pl',
      default: true,
      page: 'my-account'
    }
  ];

  roleEnum = RoleEnum;

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private i18nRoute: I18nRoutePipe,
    private globalStateService: GlobalStateHelper,
  ) {
    super();
  }

  ngOnInit(): void {
    this.newPasswordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]],
      plainPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]],
      plainPasswordRepeat: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]]
    }, { validator: matchingFieldsValidate('plainPassword', 'plainPasswordRepeat')});

    this.user = this.userService.userValue;
    this.userService.user
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( user => {
        this.user = user;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  showPopup(): void {
    this.popupNewPassword.showPopup();
  }

  logout(): void {
    this.userService.logout(false);
    this.globalStateService.unlockLogoutPopup = true;
  }

  onSubmit(): void {
    this.errorMsg = '';
    this.successMsg = '';

    if (this.newPasswordForm.valid) {
      this.loading = true;

      this.userService.changePassword(this.newPasswordForm.value)
        .pipe(
          finalize( () => { this.loading = false; } ),
          takeUntil( this.componentDestroyed$ )
        )
        .subscribe(_ => {
          this.newPasswordForm.reset();
          this.successMsg = 'password_changed';
        }, err => {
          if ( typeof err === 'object' ) {
            this.errors = err;
          } else {
            this.errorMsg = err;
          }
        });
    } else {
      this.input.toArray().forEach(field => {
        field.validateField();
      });
    }
  }

  resetForm() {
    this.newPasswordForm.reset();
    this.errorMsg = '';
    this.successMsg = '';
  }
}
