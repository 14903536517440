import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import ConfigService from '@config/ConfigService';
import { RouteMapHelper } from '../../../_helpers/RouteMapHelper';
import { UserAuthHelper } from '@helpers/UserAuthHelper';
import { UserService } from '@services/auth/user.service';
import { first } from 'rxjs/operators';
import { I18nRoutePipe } from 'src/app/_pipes/i18n-route.pipe';

@Injectable({
  providedIn: 'root'
})
export class LogoutUserGuard  {

  private currentLang: string;

  constructor(
    private router: Router,
    private routeMapHelper: RouteMapHelper,
    private userAuthHelper: UserAuthHelper,
    private userService: UserService,
    private i18nRoute: I18nRoutePipe
  ) {
    this.currentLang = ConfigService.getCurrentLang();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if ( this.userService.isLoggedIn$.value === false ) {
      return true;
    } else {
      this.router.navigate(
        [
          this.i18nRoute.transform('/:lang/blogostan')
        ]);

      return false;
    }
  }

}
