import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import ConfigService from '@config/ConfigService';
import {UserService} from '@services/auth/user.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {I18nRoutePipe} from '../_pipes/i18n-route.pipe';
import { ReservationDataService } from '@services/reservations/reservation-data.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private router: Router,
    private userService: UserService,
    private i18nRoutePipe: I18nRoutePipe
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const user = this.userService.userValue;
    if (user) {
      return this.userService.checkIsLoggedIn().pipe(
        tap({
          next: val => val,
          error: error => {
            this.userService.logout( true );

            return false;
          },
        })
      );
    }

    // not logged in so redirect to login page with the return url
    const lang = ConfigService.getCurrentLang();
    const i18nRoute = this.i18nRoutePipe.transform('/:lang/my-reservation/authorization-group/sign-in-group', lang);
    this.router.navigate([i18nRoute], {

      queryParams: {
        returnUrl: state.url
      }
    });

    return false;
  }
}
