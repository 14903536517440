<div class="pt-xl-zetta pt-xl-yotta container wide">
  <div class="address row pb-mega">
    <div class="container mobile-padding">
      <div class="offset-xl-1">
        <h2 class="pt-exa heading">{{header|translate}}</h2>
        <div class="pt-giga">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <div class="map col-xl-6 before-text pt-peta pt-sm-exa pt-xl-zero">
      <h6 class="text-acumin-thin">{{textAboveMap}}</h6>
      <google-map
        [options]="options"
        width="100%"
        height="100%"
      >
        <map-marker
          [position]="markerPosition"
          [options]="markerOptions"
        ></map-marker>
      </google-map>
    </div>
  </div>
</div>
