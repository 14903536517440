import {Component, Input, OnInit} from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@hkjeffchan/ngx-scroll-to';
import GlobalFunctions from '@helpers/GlobalFunctions';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  anchor = '';
  scrollToConfig: ScrollToConfigOptions;

  @Input() buttonText: string;
  @Input() buttonHref?: string;
  @Input() buttonLight: boolean;
  @Input() scrollTo?: string;

  constructor(
    private scrollToService: ScrollToService
  ) { }

  ngOnInit() {
  }

  onClick( e: MouseEvent ): void {
    if ( this.scrollTo ) {
      e.preventDefault();

      this.anchor = this.scrollTo;

      const map = GlobalFunctions.getScrollToOffsetMap();

      this.scrollToConfig = {
        target: this.scrollTo,
        offsetMap: map
      };

      this.scrollToService.scrollTo( this.scrollToConfig );
    } 
  }

}
