import { Component, OnInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { matchingFieldsValidate } from '../../../_helpers/validators';
import { InputField } from '../../../_helpers/InputField';
import { UserService } from '@services/auth/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, finalize } from 'rxjs/operators';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import { I18nRoutePipe } from '../../../../../_pipes/i18n-route.pipe';

@Component({
  selector: 'app-new-password-popup',
  templateUrl: './new-password-popup.component.html',
  styleUrls: ['./new-password-popup.component.scss']
})

/**
 * Popup odzyskiwania hasła, kiedy użytkownik
 * nie jest zalogowany
 * Po wejściu w komponent, sprawdza poprawność uuid
 */
export class NewPasswordPopupComponent extends SubscribeHelper implements OnInit, OnDestroy {
  newPasswordForm: UntypedFormGroup;
  successMsg = '';
  errorMsg = '';
  loading = false;
  uuid: string;

  isError: boolean = null;
  formErrors = null;

  @ViewChildren('input') input: QueryList<InputField>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private i18nRoute: I18nRoutePipe
  ) { super(); }

  ngOnInit(): void {
    this.isError = false;
    this.uuid = this.route.snapshot.queryParams.token;
    if ( !this.uuid ) {
      this.isError = true;
    } else {
      this.userService.checkUUID(this.uuid)
        .pipe( takeUntil(this.componentDestroyed$) )
        .subscribe(_ => {
          this.isError = false;
        }, _ => {
          this.isError = true;
        });
    }

    this.newPasswordForm = this.formBuilder.group({
      plainPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]],
      plainPasswordRepeat: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]]
    }, { validator: matchingFieldsValidate('plainPassword', 'plainPasswordRepeat')});
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onSubmit() {
    this.errorMsg = '';
    this.successMsg = '';
    this.formErrors = {};

    if (this.newPasswordForm.valid) {
      this.loading = true;

      this.userService.newPassword( this.newPasswordForm.value, this.uuid )
        .pipe(
          takeUntil( this.componentDestroyed$ ),
          finalize( () => { this.loading = false; } )
        )
        .subscribe(_ => {
          this.successMsg = 'password_changed';
          this.newPasswordForm.reset();

          setTimeout(() => {
            this.router.navigate([
                this.i18nRoute.transform('/:lang/my-reservation/authorization-group/sign-in-group')
              ]
            );
          }, 3000);
        }, err => {
          if ( typeof err === 'object' ) {
            this.formErrors = err;
          } else {
            this.errorMsg = 'new_password_error';
          }
        });

    } else {
      this.input.toArray().forEach(field => {
        field.validateField();
      });
    }
  }
}
