import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from '../i18n/i18n.module';
import { RouterModule } from '@angular/router';
import { TextSubtleHeaderComponent } from './text-subtle-header/text-subtle-header.component';
import { AddressComponent } from './address/address.component';
import { TextMediumTitleButtonComponent } from './text-medium-title-button/text-medium-title-button.component';
import { TextSimpleCenteredComponent } from './text-simple-centered/text-simple-centered.component';
import { MovieTextHoverComponent } from './movie-text-hover/movie-text-hover.component';
import { ContainerH3HeaderComponent } from './container-h3-header/container-h3-header.component';
import { HotelHistoryComponent } from './hotel-history/hotel-history.component';
import { TextCenteredImageComponent } from './text-centered-image/text-centered-image.component';
import { ImageDoubledDescriptionComponent } from './image-doubled-description/image-doubled-description.component';
import { TextOfferComponent } from './text-offer/text-offer.component';
import { HeadingComponent } from './heading/heading.component';
import { IndentContentImagesComponent } from './indent-content-images/indent-content-images.component';
import { ImagesCollageComponent } from './images-collage/images-collage.component';
import { IndentContentBetweenImagesComponent } from './indent-content-between-images/indent-content-between-images.component';
import { AfterBeforeGreyComponent } from './after-before-grey/after-before-grey.component';
import { ImgComponent } from './img/img.component';
import { GalleryComponent } from './gallery/gallery.component';
import { DiscoverComponent } from './discover/discover.component';
import { PriceComponent } from './price/price.component';
import { ImagesCarouselComponent } from './images-carousel/images-carousel.component';
import { SingleImageTextComponent } from './single-image-text/single-image-text.component';
import { ContentImagesOverlappingRightComponent } from './content-images-overlapping-right/content-images-overlapping-right.component';
import { SharedBannerModule } from '../_shared-modules/shared-banner.module';
import { SharedPipesModule } from '../_shared-modules/shared-pipes.module';
import { SharedButtonsModule } from '../_shared-modules/shared-buttons.module';
import {Shared2plus1Module} from '../_shared-modules/shared-2plus1.module';
import { SharedGalleryModule } from '../_shared-modules/shared-gallery.module';
import { IndentContentComponent } from './indent-content/indent-content.component';
import { IndentContentModule } from '../_shared-modules/shared-indent-content.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ImageOrVideoComponent } from './image-or-video/image-or-video.component';
import { GiftCardComponent } from './gift-card/gift-card.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TripleImagesComponent } from '@shared/triple-images/triple-images.component';
import { SimpleGalleryComponent } from './simple-gallery/simple-gallery.component';
import { TextBoxComponent } from './text-box/text-box.component';
import {SharedLoaderModule} from '../_shared-modules/shared-loader.module';
import { WellnessMenuBtnComponent } from './wellness-menu-btn/wellness-menu-btn.component';
import { AlaCarteMenuBtnComponent } from './ala-carte-menu-btn/ala-carte-menu-btn.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    TextSubtleHeaderComponent,
    AddressComponent,
    TextMediumTitleButtonComponent,
    TextSimpleCenteredComponent,
    MovieTextHoverComponent,
    ContainerH3HeaderComponent,
    HotelHistoryComponent,
    TextCenteredImageComponent,
    ImageDoubledDescriptionComponent,
    TextOfferComponent,
    AfterBeforeGreyComponent,
    HeadingComponent,
    IndentContentImagesComponent,
    ImagesCollageComponent,
    IndentContentBetweenImagesComponent,
    ImgComponent,
    DiscoverComponent,
    PriceComponent,
    ImagesCarouselComponent,
    SingleImageTextComponent,
    ContentImagesOverlappingRightComponent,
    ImageOrVideoComponent,
    GiftCardComponent,
    SimpleGalleryComponent,
    TextBoxComponent,
    TripleImagesComponent,
    WellnessMenuBtnComponent,
    AlaCarteMenuBtnComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    IndentContentModule,
    RouterModule,
    SharedBannerModule,
    SharedPipesModule,
    SharedButtonsModule,
    Shared2plus1Module,
    SharedGalleryModule,
    LazyLoadImageModule,
    NgSelectModule,
    SharedLoaderModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    HttpClientModule
  ],
  providers: [

  ],
  bootstrap: [],
  exports: [
    TextSubtleHeaderComponent,
    AddressComponent,
    TextMediumTitleButtonComponent,
    TextSimpleCenteredComponent,
    ContainerH3HeaderComponent,
    MovieTextHoverComponent,
    HotelHistoryComponent,
    TextCenteredImageComponent,
    ImageDoubledDescriptionComponent,
    TextOfferComponent,
    AfterBeforeGreyComponent,
    IndentContentComponent,
    IndentContentImagesComponent,
    ImagesCollageComponent,
    IndentContentBetweenImagesComponent,
    ImgComponent,
    GalleryComponent,
    DiscoverComponent,
    ImagesCarouselComponent,
    SingleImageTextComponent,
    ContentImagesOverlappingRightComponent,
    PriceComponent,
    ImageOrVideoComponent,
    GiftCardComponent,
    SimpleGalleryComponent,
    TextBoxComponent,
    TripleImagesComponent,
    WellnessMenuBtnComponent,
    AlaCarteMenuBtnComponent,
  ]
})
export class SharedModule {}
