// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultLanguage: 'pl',
  languages: ['pl', 'de', 'en'],
  domain: 'https://shuumhotel.web4.artflash.pl',
  languageData: [
    { lang: 'pl', currency: 'PLN' },
    { lang: 'de', currency: 'PLN' },
    { lang: 'en', currency: 'PLN' }
  ],

  routeLanguageKey: ':lang',

  showMobileMenuMaxWidth: 1139,
  googleMapKey: 'AIzaSyA02zjBt7sNo_0eXAazMmGnk7kPWu28D_Y',
  shuumApiHost: 'https://api.shuum.web4.artflash.pl/api/v1/public',
  shuumApiHostShort: 'https://api.shuum.web4.artflash.pl/api/v1',
  imageHost: 'https://api.shuum.web4.artflash.pl/',
  weatherHost: 'https://api.openweathermap.org/data/2.5/weather',
  weatherApiKey: 'd616d9e86f9eef919bad7abc2a6b4aaa',
  imgwHost: 'https://danepubliczne.imgw.pl/api/data',
  blogostanUrl: 'https://blogostan.shuumhotel.pl',
  blogostganAuthUrl: 'https://blogostan.shuumhotel.pl/wp-json/shuum/v1/panel-auth/',

  reservationStorageKey: 'reservation',
  gtmKey: 'GTM-5BG2VVK'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
