import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { InputField } from '../../../_helpers/InputField';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent extends InputField {
  @ViewChild('checkboxRef') checkboxElementRef: ElementRef;
  @Input() text: string;
  @Input() bigger?: boolean;
  @Input() dark?: boolean;
  @Input() selectNone?: boolean;
  @Input() value?: number;
  @Input() filters?: boolean;
  @Output() checkboxChanged?: EventEmitter<boolean> = new EventEmitter<boolean>();

  type = 'password';

  constructor( ) {
    super();
  }

  emitCheck( control: UntypedFormControl ) {
    this.changeValidationMsg( control.errors );

    if ( this.checkboxChanged ) {
      this.checkboxChanged.emit(control.value);
    }
  }

  togglePassword() {
    if ( this.type === 'password' ) {
      this.type = 'text';
    } else if ( this.type === 'text' ) {
      this.type = 'password';
    }
  }
}
