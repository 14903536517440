import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import {UserService} from '@services/auth/user.service';
import {User} from '../../../../../../../../_models/auth/User';
import {takeUntil, map, debounce, finalize, filter, tap} from 'rxjs/operators';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import { RuleService } from '@services/auth/rule.service';
import RuleEnum from '@enums/RuleEnum';
import IRule from '@interfaces/IRule';
import { checkboxValidate } from 'src/app/_subprojects/reservation/_helpers/validators';
import { combineLatest, interval } from 'rxjs';
import { LayoutService } from '@services/layout.service';
import * as cloneDeep from 'lodash/cloneDeep';
import ConfigService from '@config/ConfigService';
import {PopupContentComponent} from '../../../../../../_shared/popup-content/popup-content.component';
import {Router} from '@angular/router';
import {I18nRoutePipe} from '../../../../../../../../_pipes/i18n-route.pipe';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent extends SubscribeHelper implements OnInit, OnDestroy {
  rules: IRule[] = [];
  checkboxForm: UntypedFormGroup;
  user: User;
  controlsLength = 0;
  public readonly currentLang: string;

  @ViewChild('popupLoyaltyProgramCancellation') popupLoyaltyProgramCancellation: PopupContentComponent;
  @ViewChild('popupLoyaltyProgramStay') popupLoyaltyProgramStay: PopupContentComponent;
  @ViewChild('popupLoyaltyProgramCancelled') popupLoyaltyProgramCancelled: PopupContentComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private ruleService: RuleService,
    private layoutService: LayoutService
  ) {
    super();
    this.currentLang = ConfigService.getCurrentLang();
  }

  ngOnInit(): void {
    this.checkboxForm = this.formBuilder.group({
      rules: this.formBuilder.array([])
    });

    this.user = this.userService.userValue;
    this.userService.getMe()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( user => {
        this.user = user;

        if ( user?.complete ) {
          this.ruleService
            .getRules(RuleEnum.Registration)
            .pipe(
              map(data => data?.rules),
              takeUntil( this.componentDestroyed$ )
            )
            .subscribe(rules => {
              if ( rules !== this.rules ) {
                this.controlsLength = rules.length;
                this.prepareRules(rules, user?.rules);

                if (user.loyaltyProgram) {
                  for (const control of this.r.controls) {
                    setTimeout(() => {
                      control.disable();
                    }, 50);
                  }
                }
              }
            });
        }
      });
  }

  get f() { return this.checkboxForm.controls; }
  get r() { return this.f.rules as UntypedFormArray; }

  prepareRules(rules: IRule[], checked: number[]): void {
    this.rules = rules;

    this.rules.forEach(rule => {
      const isChecked = checked ? checked.includes(rule.id) : false;

      let config = [isChecked, []];

      if (!rule.isVoluntary) {
        config = [true, [checkboxValidate]];
      }

      this.r.push(this.formBuilder.group({
        ['rule_' + rule.id]: config
      }));
    });
  }

  getUser() {
    this.userService.user
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( user => {
        this.user = user;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  formChanges() {
    const rules = this.checkboxForm.getRawValue();
    const mapped = rules.rules.map(x => { // ['rule_4', 'rule_5'...]
      return Object.keys(x)[0];
    });

    if ( this.user.rules ) {
      this.user.rules.forEach(rule => {
        if ( !mapped.includes(`rule_${rule}`) ) {
          rules.rules.push({ [`rule_${rule}`]: true });
        }
      });
    }

    this.layoutService.setItemLoader(true);
    this.userService.editUser({ ...this.user, ...rules })
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => {
          this.layoutService.setItemLoader(false);
        })
      )
      .subscribe(() => { });
  }

  showPopup(): void {
    this.popupLoyaltyProgramCancellation.showPopup();
  }

  closePopup() {
    this.popupLoyaltyProgramCancellation.closePopup();
    this.popupLoyaltyProgramStay.showPopup();
  }

  cancelMembership() {
    this.popupLoyaltyProgramCancellation.closePopup();

    this.layoutService.setItemLoader(true);

    const data = {
      ...this.userService.userValue,
      rules: this.userService.userValue.rules ? cloneDeep(this.userService.userValue.rules) : [],
      loyaltyProgram: false
    };

    this.userService.editUser( data )
      .pipe(
        takeUntil(this.componentDestroyed$),
        finalize(() => {
          this.layoutService.setItemLoader(false);
        })
      )
      .subscribe(_ => {
        this.user = {
          ...this.user,
          loyaltyProgram: 0
        };

        for (const control of this.r.controls) {
          control.enable();
        }

        this.popupLoyaltyProgramCancelled.showPopup();
      });
  }
}
