import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {I18nModule} from '../i18n/i18n.module';

import {ButtonComponent} from '@shared/button/button.component';
import {LineBtnComponent} from '@shared/line-btn/line-btn.component';
import {LinkComponent} from '@shared/link/link.component';
import {RouterModule} from '@angular/router';
import {SharedPipesModule} from './shared-pipes.module';
import {ScrollToModule} from '@hkjeffchan/ngx-scroll-to';


@NgModule({
  declarations: [
    ButtonComponent,
    LineBtnComponent,
    LinkComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    SharedPipesModule,
    ScrollToModule.forRoot()
  ],
  providers: [

  ],
  bootstrap: [],
  exports: [
    ButtonComponent,
    LineBtnComponent,
    LinkComponent
  ]
})
export class SharedButtonsModule { }
