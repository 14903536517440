import {Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {MetaService} from './_services/meta.service';
import {filter, map, takeUntil, first} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {I18nRoutePipe} from './_pipes/i18n-route.pipe';
import {environment} from '../environments/environment';
import ConfigService from '@config/ConfigService';
import {Observable} from 'rxjs';
import {ScrollToService} from '@hkjeffchan/ngx-scroll-to';
import {debounceEvent} from './_decorators/DebounceEvent';
import {LayoutService} from '@services/layout.service';
import {SubscribeHelper} from '@helpers/SubscribeHelper';
import {GlobalStateHelper} from '@helpers/GlobalStateHelper';
import GlobalFunctions from '@helpers/GlobalFunctions';
import {PopupContentComponent} from './_subprojects/reservation/_shared/popup-content/popup-content.component';
import {NgSelectConfig} from '@ng-select/ng-select';
import {RouteMapHelper} from '@helpers/RouteMapHelper';

declare const ga: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    I18nRoutePipe
  ]
})
export class AppComponent implements OnInit {
  title = 'ssr-with-i18n';
  private magic: any;
  private currentRoute: string;
  private previousScrollToData = null;
  private readonly currentLang: string;
  private notTranslatableGroups: string[] = [];
  private currentFragment = '';
  private gtmInitialized = false;
  state: Observable<object>;
  isSpinnerVisibile$: Observable<boolean> = this.layoutService.isNavigationPending$;

  @ViewChild('popupError500') popupError500: PopupContentComponent;

  constructor(
    private router: Router,
    private metaService: MetaService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private i18nRoute: I18nRoutePipe,
    private scrollToService: ScrollToService,
    private layoutService: LayoutService,
    private globalStateHelper: GlobalStateHelper,
    private renderer: Renderer2,
    private ngSelectConfig: NgSelectConfig,
    private routeMapHelper: RouteMapHelper,
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.currentLang = ConfigService.getCurrentLang();
    this.notTranslatableGroups = [
      'details',
      'career-child',
    ];

        
    if (!environment.production) {
      this.metaService.setRobots();
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          this.currentRoute = this.activatedRoute.snapshot['_routerState'].url;
          ConfigService.setCurrentRoute(this.currentRoute);

          return this.activatedRoute.snapshot;
        }),
        map(route => {
          let data = [];
          while (route.firstChild) {
            data.push(route.firstChild);
            route = route.firstChild;
          }

          return data;
        })
      )
      .subscribe((routes: ActivatedRouteSnapshot[]) => {
        this.resetItemLoader();
        this.changeMetaTags(routes);
        this.scrollToTop(routes);
        this.setDarkMenu(routes);
        this.scrollToPlace(routes);
        this.addClassesToElements(routes);
        this.setLangHidden(routes);

        this.isReservationRoute(routes);
        this.isMyReservationsRoute(routes);
        this.isHomepageRoute(routes);

        if (!this.gtmInitialized) {
          this.gtmInitialized = true;
          this.globalStateHelper.setGtmInitialized(this.checkGtmStatus(routes));
          // this.checkGtmStatus(routes);
        }


        //poniższe do wrzucenia w funckję po weryfikacji działania
        if (typeof ga !== 'undefined') {
          const trackerName = ga.getAll()[0].get('name');

          ga(trackerName + '.set', 'location', this.router.url );
          ga(trackerName + '.send', 'pageview');
        }

        // this.clearScrollToTopBlock();
      });

    this.addClassToHTML();
    this.subsctibeTo500Popup();
    this.setSelectDefaults();
  }


  /* potrzebne do zdjec lazy load */
  @HostListener('window:resize')
  @debounceEvent()
  onResize() {
    window.scrollTo(window.scrollX, window.scrollY - 1);
    window.scrollTo(window.scrollX, window.scrollY + 1);
  }

  resetItemLoader() {
    /**
     * Czasem komponent się niszczył przed zfinalizowaniem
     * zapytania, przez co loader zostawał
     */
    this.layoutService.setItemLoaderForce(0);
  }

  subsctibeTo500Popup(): void {
    this.layoutService.getError500Popup().subscribe(_ => {
      this.popupError500.showPopup();
    });
  }

  addClassesToElements(routes: ActivatedRouteSnapshot[]) {
    if (routes[routes.length - 1]?.data?.loginHidden && this.globalStateHelper.loginVisible$.value) {
      this.globalStateHelper.setLoginVisible(false);
    } else if (!routes[routes.length - 1]?.data?.loginHidden && !this.globalStateHelper.loginVisible$.value) {
      this.globalStateHelper.setLoginVisible(true);
    }

    if (routes[routes.length - 1].data && routes[routes.length - 1].data.myAccountLinkActive) {
      if (routes[routes.length - 1].data.myAccountLinkActive === 1) {
        this.globalStateHelper.updateAccountNavigationChange(1);
      } else if (routes[routes.length - 1].data.myAccountLinkActive === 2) {
        this.globalStateHelper.updateAccountNavigationChange(2);
      } else if (routes[routes.length - 1].data.myAccountLinkActive === 3) {
        this.globalStateHelper.updateAccountNavigationChange(3);
      }
    }

    if (routes[routes.length - 1].data && routes[routes.length - 1].data.especiallyForYouLinkActive) {
      if (routes[routes.length - 1].data.especiallyForYouLinkActive === 1) {
        this.globalStateHelper.updateEspeciallyForYouNavigationChange(1);
      } else if (routes[routes.length - 1].data.especiallyForYouLinkActive === 2) {
        this.globalStateHelper.updateEspeciallyForYouNavigationChange(2);
      } else if (routes[routes.length - 1].data.especiallyForYouLinkActive === 3) {
        this.globalStateHelper.updateEspeciallyForYouNavigationChange(3);
      } else if (routes[routes.length - 1].data.especiallyForYouLinkActive === 4) {
        this.globalStateHelper.updateEspeciallyForYouNavigationChange(4);
      } else if (routes[routes.length - 1].data.especiallyForYouLinkActive === 5) {
        this.globalStateHelper.updateEspeciallyForYouNavigationChange(5);
      }
    }
  }

  scrollToPlace(routes: ActivatedRouteSnapshot[]) {
    if (isPlatformBrowser(this.platformId)) {
      this.currentFragment = '';

      routes.forEach((el) => {
        if (el.fragment && el.fragment !== '') {
          this.currentFragment = el.fragment;
        }
      })

      if (this.currentFragment !== "" && this.document.getElementById(this.currentFragment)) {
        setTimeout(() => {
          if (this.currentFragment !== '') {
            const scrollToConfig = {
              target: `#${this.currentFragment}`,
              offset: -106
            };
            this.scrollToService
              .scrollTo(scrollToConfig);
          }
        }, 1000);
      }
    }
  }

  /**
   * Ustawia klasę classToAdd w obiekcie element, jeżeli
   * w roucie jest ustawiona właściwość routeProperty.
   * @param routes - ścieżki do przeszukania
   * @param element - klasa elementu, któremu zostanie nadana lub usunięta klasa
   * @param classToAdd - klasa do dodania lub usunięcia
   * @param routeProperty - właściwość w route.data, któa jest sprawdzana
   */
  checkAndSetClass(routes: ActivatedRouteSnapshot[], element: string, classToAdd: string, routeProperty: string) {
    let flag = false;

    routes.forEach(data => {
      if (data.data[routeProperty]) {
        flag = true;
      }
    });

    const el = this.document.getElementById(element) || this.document.getElementsByClassName(element)[0];

    if (flag) {
      if (!el.classList.contains(classToAdd)) {
        el.classList.add(classToAdd);
      }
    } else {
      if (el.classList.contains(classToAdd)) {
        el.classList.remove(classToAdd);
      }
    }
  }

  /**
   * Ustawienie innego menu na joga & fitness
   * @param routes - ścieżki do przeszukania
   */
  setDarkMenu(routes: ActivatedRouteSnapshot[]) {
    this.checkAndSetClass(routes, 'nav-container', 'dark-menu', 'menuDark');
  }

  /**
   * Chowanie selecta języków - na stronie
   * /program-inteligentnego-rozwoju
   * @param routes - ścieżki do przeszukania
   */
  setLangHidden(routes: ActivatedRouteSnapshot[]) {
    this.checkAndSetClass(routes, 'lang-container', 'd-none', 'langHidden');
  }

  changeMetaTags(data: ActivatedRouteSnapshot[]): void {
    const titles: string[] = [];
    let description = null;
    let keywords = null;
    const groups: string[] = [];
    let hasNonTranslatableGroup = false;

    data.forEach(d => {
      let routeData = d.data;
      let group = null;

      if (typeof routeData.meta !== 'undefined') {
        const meta = routeData.meta;

        if (typeof meta.title === 'string') {
          titles.push(meta.title);
        }

        if (typeof meta.description === 'string') {
          description = meta.description;
        }

        if (typeof meta.keywords === 'string') {
          keywords = meta.keywords;
        }
      }

      if (typeof routeData.group !== 'undefined') {
        if (!groups.includes(routeData.group)) {
          if (this.notTranslatableGroups.includes(routeData.group)) {
            if (typeof d.params.slug !== 'undefined') {
              groups.push(d.params.slug);

              hasNonTranslatableGroup = true;
            }
          } else {
            groups.push(routeData.group);
          }
        }
      }
    });

    if (titles.length) {
      this.translate.get(titles)
        .pipe(first())
        .subscribe(translatedTitles => {
          const reversedTitles = titles.reverse();
          const titlesText = [];

          reversedTitles.forEach(title => {
            if (typeof translatedTitles[title] !== 'undefined') {
              titlesText.push(translatedTitles[title]);
            }
          });

          const titleText = titlesText.join(' - ');
          this.metaService.setTitle(titleText);
        });
    }

    if (description !== null) {
      if (description) {
        this.translate.get(description)
          .pipe(first())
          .subscribe(item => {
            this.metaService.setDescription(item);
          });
      } else {
        this.metaService.setDescription('');
      }
    }

    if (keywords != null) {
      if (keywords) {
        this.translate.get(keywords)
          .pipe(first())
          .subscribe(item => {
            this.metaService.setKeywords(item);
          });
      } else {
        this.metaService.setKeywords('');
      }
    }

    // if (groups.length > 0) {
    //   this.setAlternateLink(groups, hasNonTranslatableGroup);
    // }
    this.setAlternateLink(groups, hasNonTranslatableGroup);

    this.metaService.setUrl(this.currentRoute);
  }

  isReservationRoute(data: ActivatedRouteSnapshot[]) {
    data.forEach(d => {
      const routeData = d.data;
      if (routeData.isReservationRoute) {
        this.layoutService.setIsReservationRoute(true);
      } else {
        this.layoutService.setIsReservationRoute(false);
      }
    });
  }

  isMyReservationsRoute(data: ActivatedRouteSnapshot[]) {
    data.forEach(d => {
      const routeData = d.data;
      if (routeData.isMyReservationsRoute) {
        this.layoutService.setIsMyReservationsRoute(true);
      } else {
        this.layoutService.setIsMyReservationsRoute(false);
      }
    });
  }

  isHomepageRoute(data: ActivatedRouteSnapshot[]) {
    data.forEach(d => {
      const routeData = d.data;
      if (routeData.isHomepageRoute) {
        this.layoutService.setIsHomepageRoute(true);
      } else {
        this.layoutService.setIsHomepageRoute(false);
      }
    });
  }

  checkGtmStatus(data: ActivatedRouteSnapshot[]) {
    let omitGTM = false;
    data.forEach(d => {
      const routeData = d.data;
      if ((d.queryParams.action === 'payment-success' && d.routeConfig.path === 'redirector') || routeData.omitGTM) {
        omitGTM = true;
      }
    });
    if (!omitGTM) {
      return this.loadGoogleAnalytics(environment.gtmKey);
    }
    return false;
  }

  loadGoogleAnalytics(trackingID: string): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const gaScript2 = document.createElement('script');
      gaScript2.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer',"${trackingID}");`;

      document.documentElement.firstChild.appendChild(gaScript2);
      return true;
    }
    return false;
  }

  scrollToTop(data: ActivatedRouteSnapshot[]) {
    let scroll = true;

    data.forEach(snapshot => {
      if (snapshot.data && snapshot.data.blockedScroll) {
        scroll = false;
      }
    });

    if (window && scroll) {
      const length = data.length;
      let d = typeof data[length - 1] !== 'undefined' ? data[length - 1] : null;
      const supportsNativeSmoothScroll = 'scrollBehavior' in this.document.documentElement.style;
      /**
       * Flaga używama do zwiększenia setTimeout przy przejściu do szczegółów pokoju ze strony głównej,
       * sprawdzamy czy poprzedni route to strona główna i pochodzi z sekcji Rooms
       */
      const isHomepagePreviousRoute = !this.routeMapHelper.getGroup( ConfigService.getPreviousRoute().split('/')[2] )
                                      && this.layoutService.backToClickedElementStore;

      try {
        if (d) {
          setTimeout(() => {
            let offsetTop = 0;
            const routeData = d.data;
            const scrollToSelector = typeof routeData.scrollTo !== 'undefined' ? routeData.scrollTo : null;

            if (scrollToSelector) {
              if (this.previousScrollToData) {
                // const scrollToParentSelector = typeof routeData.scrollListTo !== 'undefined' ? routeData.scrollListTo : null;
                const scrollParentOffset = typeof routeData.scrollOffsetParent !== 'undefined' ? routeData.scrollOffsetParent : false;
                const element = this.document.querySelector(scrollToSelector);

                if (element) {
                  offsetTop = element.offsetTop;

                  if (scrollParentOffset) {
                    const parent = this.document.querySelector(scrollParentOffset);

                    if (parent) {
                      offsetTop += parent.offsetTop;
                    }
                  }
                }
              }
            } else if (this.previousScrollToData && !routeData.preventScroll) { // preventScroll - zapobiega scrollowaniu przy przejsciu z podstrony oferty na inną
              const parentRouteData = this.previousScrollToData.data;
              const scrollToParentSelector = typeof parentRouteData.scrollListTo !== 'undefined' ? parentRouteData.scrollListTo : null;
              const scrollParentOffset = typeof parentRouteData.scrollListOffsetParent !== 'undefined' ? parentRouteData.scrollListOffsetParent : false;
              const element = this.document.querySelector(scrollToParentSelector);

              if (element) {
                offsetTop = element.offsetTop;

                if (scrollParentOffset) {
                  const parent = this.document.querySelector(scrollParentOffset);

                  if (parent) {
                    offsetTop += parent.offsetTop;
                  }
                }
              }
            }

            if (typeof window.scrollTo === 'function') {
              setTimeout(() => {
                if (supportsNativeSmoothScroll) {

                  window.scrollTo({
                    top: offsetTop,
                    // behavior: 'smooth'
                  });
                } else {
                  window.scrollTo(0, offsetTop);
                }

              }, scrollToSelector ? 300 : 300);
            }

            this.previousScrollToData = Object.assign({}, d);
          }, isHomepagePreviousRoute ? 1500 : 300);
        }
      } catch (e) {
        if (typeof window.scrollTo === 'function') {
          if (supportsNativeSmoothScroll) {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          } else {
            window.scrollTo(0, 0);
          }
        }
      }
    }
  }

  setAlternateLink(groups: string[], hasNonTranslatableGroup: boolean): void {
    const langKey = environment.routeLanguageKey;
    const languages = environment.languages;
    groups.unshift(langKey);

    ConfigService.setCurrentRouteGroupPath(groups);

    if (!hasNonTranslatableGroup) {
      const groupsAsString = groups.join('/');

      languages.forEach(lang => {
        if (lang !== this.currentLang) {
          const href = this.i18nRoute.transform(groupsAsString, lang);

          this.metaService.setAlternateURL({href, hrefLang: lang});
        }
      });
    }
  }

  addClassToHTML() {
    const canUseQuery = GlobalFunctions.getCanBrowserUsePixelRatioQuery();

    if (canUseQuery) {
      this.document.documentElement.classList.add('use-ratio');
    } else {
      this.document.documentElement.classList.remove('use-ratio');
    }
  }

  setSelectDefaults() {
    this.translate.get(['select_not_found', 'select_clear_all'])
      .pipe(first())
      .subscribe(item => {
        this.ngSelectConfig.notFoundText = item.select_not_found;
        this.ngSelectConfig.clearAllText = item.select_clear_all;
      });


    // this.ngSelectConfig.appendTo = 'body';
  }
}
