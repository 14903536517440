import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {I18nModule} from '../i18n/i18n.module';

import { GalleryComponent } from '@shared/gallery/gallery.component';
import { SharedPipesModule } from './shared-pipes.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {GalleryFiltersComponent} from '@shared/gallery/gallery-filters/gallery-filters.component';


@NgModule({
  declarations: [
    GalleryComponent,
    GalleryFiltersComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    SharedPipesModule,
    LazyLoadImageModule
  ],
  providers: [

  ],
  bootstrap: [],
  exports: [
    GalleryComponent,
    GalleryFiltersComponent
  ]
})
export class SharedGalleryModule { }
