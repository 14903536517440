<app-banner
  [header]="routeDataParams?.pageData?.header"
  [description]="routeDataParams?.pageData?.description"
  [background]="routeDataParams?.pageData?.background"
  [background2x]="routeDataParams?.pageData?.background2x"
>
  <app-line-btn
    class="h4"
    [scrollTo]="routeDataParams?.pageData?.wrapperId"
    [noOffset]="true"
    bottomButton
  >
    <span>{{ 'check_offers'|translate }}</span>
  </app-line-btn>
</app-banner>

<div [id]="routeDataParams?.pageData?.wrapperId" class="position-relative" [@routeAnimation]="o.activatedRouteData">
  <router-outlet #o="outlet"></router-outlet>
</div>
