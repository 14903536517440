import { NgModule } from '@angular/core';
import {CommonModule, isPlatformServer} from '@angular/common';
import {I18nModule} from '../i18n/i18n.module';

import {Content2plus1Component} from '@shared/content2plus1/content2plus1.component';
import { SharedPipesModule } from './shared-pipes.module';
import { SharedButtonsModule } from './shared-buttons.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';

@NgModule({
  declarations: [
    Content2plus1Component
  ],
  imports: [
    CommonModule,
    I18nModule,
    SharedPipesModule,
    SharedButtonsModule,
    LazyLoadImageModule
  ],
  providers: [

  ],
  bootstrap: [],
  exports: [
    Content2plus1Component
  ]
})
export class Shared2plus1Module { }
