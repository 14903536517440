import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, FormControl } from '@angular/forms';
import { IImage } from '@interfaces/IImage';
import { InputField } from 'src/app/_subprojects/reservation/_helpers/InputField';
import { UserService } from '@services/auth/user.service';
import { User } from 'src/app/_models/auth/User';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { SubscribeHelper } from '@helpers/SubscribeHelper';
import ICountry from 'src/app/_subprojects/reservation-form/_interfaces/ICountry';
import GlobalFunctions from '@helpers/GlobalFunctions';
import CountryEnum from 'src/app/_subprojects/reservation-form/_enums/CountryEnum';
import { IValidationMsg } from 'src/app/_subprojects/reservation/_interfaces/IValidationMsg';
import { fadeAnimation } from '@helpers/animations';
import { CompanyService } from 'src/app/_subprojects/reservation/_services/company.service';
import { RuleService } from '@services/auth/rule.service';
import RuleEnum from '@enums/RuleEnum';
import { combineLatest } from 'rxjs';
import IRule from '@interfaces/IRule';
import * as cloneDeep from 'lodash/cloneDeep';
import { PopupContentComponent } from 'src/app/_subprojects/reservation/_shared/popup-content/popup-content.component';

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss'],
  animations: [ fadeAnimation ]
})
export class EditAccountComponent extends SubscribeHelper implements OnInit, OnDestroy {
  editForm: UntypedFormGroup;
  vatEditForm: UntypedFormGroup;
  images: IImage[] = [
      {
        image: '/assets/images/reservations/user-profile/avatars/01.png',
        image2x: '/assets/images/reservations/user-profile/avatars/01@2x.png'
      },
      {
        image: '/assets/images/reservations/user-profile/avatars/02.png',
        image2x: '/assets/images/reservations/user-profile/avatars/02@2x.png'
      },
      {
        image: '/assets/images/reservations/user-profile/avatars/03.png',
        image2x: '/assets/images/reservations/user-profile/avatars/03@2x.png'
      },
      {
        image: '/assets/images/reservations/user-profile/avatars/04.png',
        image2x: '/assets/images/reservations/user-profile/avatars/04@2x.png'
      },
      {
        image: '/assets/images/reservations/user-profile/avatars/05.png',
        image2x: '/assets/images/reservations/user-profile/avatars/05@2x.png'
      },
      {
        image: '/assets/images/reservations/user-profile/avatars/06.png',
        image2x: '/assets/images/reservations/user-profile/avatars/06@2x.png'
      }
    ];

  editUserMsg: IValidationMsg;
  editInvoiceMsg: IValidationMsg;
  invoiceToDelete = false;
  popoverVisible = false;

  msgTranslation = {
    success: 'changes_saved',
    error: 'changes_failed'
  };

  user: User;
  rules: IRule[] = [];


  countries: ICountry[] = [];

  @ViewChildren('input') input: QueryList<InputField>;
  @ViewChildren('inputInvoice') inputInvoice: QueryList<InputField>;
  @ViewChild('popup') popup: PopupContentComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private companyService: CompanyService,
    private ruleService: RuleService
  ) { super(); }

  ngOnInit(): void {
    const emailRegex = GlobalFunctions.getEmailRegex();
    const phoneRegex = GlobalFunctions.getPhoneRegex();

    this.countries = GlobalFunctions.enumAsArray( CountryEnum );

    this.editForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.pattern(emailRegex), Validators.minLength(3), Validators.maxLength(100)]],
      phone: ['', [Validators.required, Validators.pattern(phoneRegex), Validators.maxLength(20)]],
      address: ['', [Validators.required, Validators.maxLength(50)]],
      postcode: ['', [Validators.required, Validators.maxLength(10)]],
      city: ['', [Validators.required, Validators.maxLength(50)]],
      country: ['', [Validators.required]]
    });

    this.vatEditForm =  this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      address: ['', [Validators.required, Validators.maxLength(50)]],
      postcode: ['', [Validators.required, Validators.maxLength(10)]],
      city: ['', [Validators.required, Validators.maxLength(50)]],
      taxNumber: ['', [Validators.required, Validators.maxLength(20)]],
      country: ['', [Validators.required]]
    });

    const user$ = this.userService.user;
    const rules$ = this.ruleService.getRules(RuleEnum.Registration);

    combineLatest( [rules$, user$] )
      .pipe(
        map(data => ({
          rules: data[0]?.rules,
          user: data[1]
        })),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe( data => {
        this.user = cloneDeep(data.user);

        if ( data.rules !== this.rules ) {
          this.rules = cloneDeep(data.rules);
        }

        if ( data.user?.company?.id ) {
          this.invoiceToDelete = true;
          this.vatEditForm.patchValue({...data.user.company});
        } else {
          this.invoiceToDelete = false;
          this.vatEditForm.reset();
        }

        this.editForm.patchValue({...data.user});
      });

    /**
     * Odśwież dane do edycji konta
     */
    if ( this.userService.userValue ) {
      this.userService.getMe( true )
        .pipe(
          takeUntil(this.componentDestroyed$)
        )
        .subscribe(_ => { });
    }
  }

  onEditSubmit(): void {
    this.editUserMsg = { success: '', error: '', loading: false };

    if (this.editForm.valid) {
      this.editUserMsg.loading = true;

      const data = {
        ...this.editForm.value,
        rules: this.user.rules ? cloneDeep(this.user.rules) : [],
        loyaltyProgram: this.user.loyaltyProgram
      };

      this.rules.forEach(rule => {
        if ( !rule.isVoluntary && !data.rules.includes(rule.id) ) {
          data.rules.push( rule.id );
        }
      });

      this.userService.editUser( data )
        .pipe(
          takeUntil(this.componentDestroyed$)
        )
        .subscribe(_ => {
          this.editUserMsg.loading = false;
          this.editUserMsg.success = this.msgTranslation.success;
        }, err => {
          if ( typeof err === 'object' ) {
            this.editUserMsg.apiErrrors = err;
          }

          this.editUserMsg.loading = false;
          this.editUserMsg.error = this.msgTranslation.error;
        });

    } else {
      this.input.toArray().forEach(field => {
        field.validateField();
      });
    }
  }

  invoiceSubmit(): void {
    this.editInvoiceMsg = { success: '', error: '', loading: false };

    if (this.vatEditForm.valid) {
      this.editInvoiceMsg.loading = true;

      const data = this.vatEditForm.value || {};
      data.companyName = data.name;
      delete data.name;

      this.companyService.addCompany( this.vatEditForm.value )
        .pipe(
          finalize(() => {
            this.editInvoiceMsg.loading = false;
          }),
          takeUntil(this.componentDestroyed$)
        )
        .subscribe(_ => {
          this.editInvoiceMsg.success = this.msgTranslation.success;
        }, err => {
          if ( typeof err === 'object' ) {
            this.editInvoiceMsg.apiErrrors = err;
          }

          this.editInvoiceMsg.error = this.msgTranslation.error;
        });
    } else {
      this.inputInvoice.toArray().forEach(field => {
        field.validateField();
      });
    }
  }

  deleteInvoice() {
    this.editInvoiceMsg = { success: '', error: '', loading: false };

    if ( this.user?.company?.id ) {
      this.editInvoiceMsg.loading = true;
      this.companyService.deleteCompany( this.user.company.id )
        .pipe(
          takeUntil(this.componentDestroyed$)
        )
        .subscribe(_ => {

          this.userService.getMe()
          .pipe(
            finalize(() => {
              this.editInvoiceMsg.loading = false;
            }),
            takeUntil(this.componentDestroyed$)
          )
          .subscribe(user => {
            if ( user.company?.id ) {
              this.popup.showPopup();
            }

            this.editInvoiceMsg.success = this.msgTranslation.success;
          }, __ => {
            this.editInvoiceMsg.error = this.msgTranslation.error;
          });

        }, _ => {
          this.editInvoiceMsg.loading = false;
          this.editInvoiceMsg.error = this.msgTranslation.error;
        });
    }
  }

  handleInvoice() {
    if ( !this.invoiceToDelete ) {
      this.invoiceSubmit();
    } else {
      this.deleteInvoice();
    }
  }

  chooseImage(val: number) {
    // todo: choose image
  }

  showPopover( e: MouseEvent ): void {
    e.preventDefault();
    this.popoverVisible = true;
  }

  hidePopover( e?: any ): void {
    if ( e ) {
      e.preventDefault();
    }
    this.popoverVisible = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
