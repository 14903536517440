import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import ConfigService from '../_config/ConfigService';

@Injectable()
export class LangGuard  {
  constructor(private router: Router, private translate: TranslateService) {
    this.translate = translate;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const languages = environment.languages;
    const forLanguages: string[] = route.data.forLanguages || [];

    if ( forLanguages.length > 0 ) {
      return this.localLangChecker(languages, forLanguages, route);
    }
    else {
      return this.globalLangChecker(languages, route);
    }
  }

  private localLangChecker(languages: string[], forLanguages: string[], route: ActivatedRouteSnapshot): boolean {
    let lang = route.params.lang || '';
    if ( !lang ) {
      lang = route.parent.params.lang || '';
    }
    if ( !lang ) {
      lang = route.parent.parent.params.lang || '';
    }
    if ( !lang ) {
      lang = route.parent.parent.parent.params.lang || '';
    }
    if ( !lang ) {
      lang = route.parent.parent.parent.parent.params.lang || '';
    }
    if ( !lang ) {
      lang = route.parent.parent.parent.parent.parent.params.lang || '';
    }
    if ( !lang ) {
      lang = route.parent.parent.parent.parent.parent.parent.params.lang || '';
    }

    if ( typeof lang !== 'undefined' ) {
      if ( forLanguages.includes( lang ) ) {
        return true;
      }

      this.redirectTo404( languages );
    }

    return false;
  }

  private globalLangChecker(languages: string[], route: ActivatedRouteSnapshot): boolean {
    try {
      if ( typeof route.params.lang !== 'undefined' ) {
        const isLangAvailable = languages.includes(route.params.lang);

        if (!isLangAvailable) {
          this.redirectTo404( languages );
        }
        else {
          return true;
        }
      }
    }
    catch ( e ) {

    }

    return false;
  }

  /**
   * CurrentLang zawiera juz w sobie jezyk ustawiony w przegladarce lub ten z urla
   *
   * @param languages - lista jezykow
   */
  private redirectTo404( languages: string[] ) {
    let routeToRedirect = '/' + environment.defaultLanguage + '/404';

    if ( ConfigService.getCurrentLang() ) {
      if (typeof languages !== 'undefined' && languages.includes(ConfigService.getCurrentLang())) {
        routeToRedirect = '/' + ConfigService.getCurrentLang() + '/404';
      }
    }

    this.router.navigateByUrl(routeToRedirect);
  }
}
