import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import ConfigService from '@config/ConfigService';
import {UserService} from '@services/auth/user.service';
import {Observable} from 'rxjs';
import {I18nRoutePipe} from '../../../_pipes/i18n-route.pipe';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private router: Router,
    private userService: UserService,
    private i18nRoutePipe: I18nRoutePipe
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const user = this.userService.userValue;
    if (user) {
      if ( typeof window !== 'undefined' ) {
        if (window.document.referrer) {
          const referrer = window.document.referrer;
          const url = new URL(referrer);
          const action = url.searchParams.get('action');

          if (action && action === 'login-social') {
            const refer = new URL(decodeURIComponent(url.searchParams.get('refer')));
            const type = refer.searchParams.get('type');

            if (!type) {
              window.parent.location.href = `${environment.blogostganAuthUrl}${user.token}`;
            }
          }
        }
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    const lang = ConfigService.getCurrentLang();
    const i18nRoute = this.i18nRoutePipe.transform('/:lang/blogostan/sign-in-group', lang);
    this.router.navigate([i18nRoute], {

      queryParams: {
        type: route.queryParams.type
      }
    });

    return false;
  }
}
