import { Component, OnInit } from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-blogostan-password-recovery',
  templateUrl: './blogostan-password-recovery.component.html',
  styleUrls: ['./blogostan-password-recovery.component.scss']
})
export class BlogostanPasswordRecoveryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  formSent(_: UntypedFormGroup): void {
  }

}
